// FONT
$font-family-base: 'TrebuchetMS', sans-serif;
$font-family-sans-serif: $font-family-base;

// LAYOUT

$content-bg: #f5f5f5;
$aside-wd: 220px;
$aside-wd-collapsed: 71px;
$aside-wd-collapsed-text: 90px;
$aside-bg: $color-dark-midnight-express;

// Table

$table-color: #273a5c;

// Body

$body-bg: #ffffff;
$body-color: $color-dark-midnight-express;

$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;

$line-height-base: 1rem;

$headings-line-height: 1.1;

// Tables

$table-accent-bg: #f5f4f4;
$table-hover-bg: #f4f4f4;

$table-border-color: #eee;

// Buttons + Forms

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;

$input-height: 2.1875rem; // 35px

// Forms

$input-disabled-bg: $gray-lighter;
$input-border-color: $gray;
$input-focus-border-color: #66afe9;
$input-placeholder-color: #b7bac9;

$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

// Dropdowns

$dropdown-border-color: #e1e1e1;

// Navs

$nav-tabs-link-active-bg: #fff;

// Jumbotron

$jumbotron-bg: #fcfcfc;

// List group

$list-group-border-color: #e4eaec;

// Image thumbnails

$thumbnail-padding: 0.125rem;

// Breadcrumbs

$breadcrumb-bg: #fafafa;
$breadcrumb-divider-color: #c1c2c3;
$breadcrumb-active-color: $body-color;

// NAVBAR TOP

$nav-top-bg: $color-midnight-express;
$nav-top-bg-start: $nav-top-bg;
$nav-top-bg-end: $color-dark-midnight-express;
$nav-header-bg: transparent;

$nav-top-item-mobile: $nav-top-bg;
$nav-top-item-mobile-active: darken($nav-top-item-mobile, 20%);

$nav-top-item-desktop: $color-koncert-white;
$nav-top-item-desktop-active: $primary;

$nav-top-item: $color-koncert-white;
$nav-top-item-active: $primary;

$content-bg: $body-bg;
$content-heading-bg: #ffffff;
$content-heading-border: #e3e3e3;
$content-padding: 15px;

$footer-hg: 60px;

$navbar-hg: $navbar-height;

$boxed-max-width: 1140px;
$z-index-main-section: 111;

// SIDEBAR

$sidebar-bg: $color-midnight-express;

$sidebar-item-color: $color-koncert-white;
$sidebar-item-color-active: $color-koncert-white;
$sidebar-item-bg-active: darken($sidebar-bg, 1%);

$sidebar-icon-color: inherits;
$sidebar-icon-color-active: $primary;

$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;

$sidebar-heading-color: lighten($color-secondary-shade, 50%);
$sidebar-label-color: $sidebar-item-color;

/* ========================================================================
   Component: layout
 ========================================================================== */
body {
  background-color: $content-bg;
}

.wrapper .section-container {
  background-color: $color-koncert-white;
  min-height: calc(100vh - 114px);
}

.wrapper .aside-container {
  background-color: $aside-bg !important;
}

body {
  font-family: $font-family-base;
}

// .page-header {
//   margin: -15px -15px 15px -15px;
// }

/* ========================================================================
   Component: top-navbar
 ========================================================================== */
.topnavbar {
  background-color: $nav-top-bg;

  @include media-breakpoint-up(lg) {
    .navbar-nav > .nav-item.show > .nav-link {
      &,
      &:hover,
      &:focus {
        // box-shadow: 0 -3px 0 darken($nav-top-bg, 6%) inset;
        color: $nav-top-item-active;
      }
    }
  }

  .navbar-nav > .nav-item > .navbar-text {
    color: $nav-top-item;
  }

  .navbar-nav > .nav-item > .nav-link,
  .navbar-nav > .nav-item.show > .nav-link {
    color: $nav-top-item;
    &:hover,
    &:focus {
      color: $nav-top-item-active;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $nav-top-bg;
  }
}

/* ========================================================================
   Component: sidebar
 ========================================================================== */
.sidebar {
  background-color: $aside-bg;

  .nav-heading {
    color: $sidebar-heading-color;
  }
}

// Links
.table {
  a {
    color: $body-color;
    &:hover,
    &:focus {
      color: darken($primary, 30%);
    }
  }
}

// Items
.sidebar-nav {
  > li {
    > a,
    > .nav-item {
      color: $sidebar-item-color;

      &:focus,
      &:hover {
        background-color: $sidebar-bg;
        color: $sidebar-item-color-active;
        > em {
          color: $sidebar-icon-color-active !important;
        }
      }

      // Item icon
      > em {
        color: $sidebar-icon-color;
      }
    }

    // Active item state
    &.active,
    &.open {
      &,
      > a,
      > .nav-item,
      .sidebar-nav {
        background-color: $sidebar-item-bg-active;
        color: $sidebar-item-color-active;
      }

      > .nav-item > em,
      > a > em {
        color: $sidebar-icon-color-active;
      }
    }

    &.active {
      border-left-color: $sidebar-icon-color-active;
    }
  }
}

.sidebar-subnav {
  background-color: $sidebar-bg;
}

/* ========================================================================
   Component: offsidebar
 ========================================================================== */
$offsidebar-bg: #fff;
$offsidebar-border-color: greyscale(darken($offsidebar-bg, 20%));
$offsidebar-color: $body-color;
.offsidebar {
  border-left: 1px solid $offsidebar-border-color;
  background-color: $offsidebar-bg;
  color: $offsidebar-color;
}

/* ========================================================================
     Component: Page loader
 ========================================================================== */

.page-loader {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding-top: 90px;
  text-align: center;
}

@include media-breakpoint-up(md) {
  .section-container .page-loader {
    top: $navbar-hg;
    // left: $aside-wd;
  }
  .aside-collapsed .section-container .page-loader {
    left: $aside-wd-collapsed;
  }
  .aside-collapsed-text .section-container .page-loader {
    left: $aside-wd-collapsed-text;
  }
}

// Layout Horizontal Nav
// -----------------------------------
.layout-h {
  #root > div > header > nav {
    z-index: 0 !important;
  }
  .wrapper {
    .section-container {
      // max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
      // .content-wrapper .content-heading {
      //     display: none;
      // }
    }
    .section-container {
      margin-top: 0;
    }
    .offsidebar {
      margin-top: $navbar-hg;
    }
    .footer-container {
      margin: 0;
    }
  }
  &.layout-fixed .wrapper {
    .section-container,
    .offsidebar {
      margin-top: $navbar-hg;
    }
    .admin-section-container {
      margin-top: $navbar-hg + 37;
    }
  }
}
.layout-fixed {
  .wrapper {
    .section-container {
      margin-top: 55px;
    }
  }
}

// Page main content
// -----------------------------
.content-wrapper {
  padding: 15px 15px 5px 15px !important;
  width: 100%; // styling to visually wrap the component
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px;

  .unwrap {
    margin: -15px; // expand over wrapper padding
    @include media-breakpoint-up(md) {
      margin: -20px; // expand over wrapper padding
    }
  }

  .content-heading {
    align-items: center;
    font-size: 1.5rem;
    line-height: $headings-line-height;
    color: $color-midnight-express;
    margin: 0px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: $content-heading-bg;
    border-bottom: 1px solid $content-heading-border;
    small {
      display: block;
      font-size: 12px;
      color: $text-muted;
    }
    &.submenu {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-top: -16px;
    }
    // Search bar
    .search-bar {
      .form-control {
        &:focus {
          background: transparent;
        }
      }
      .input-group-append {
        .btn {
          &.searchbar-remove-text {
            border: 0;
          }
          &:hover {
            background-color: transparent;
          }
          &:focus {
            box-shadow: none;
          }
          i {
            pointer-events: none;
          }
        }
      }
    }
  }

  .container,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-up(md) {
    padding: $content-padding; //overflow: auto; // required for portlets
    .content-heading {
      margin: -$content-padding;
      margin-bottom: $content-padding;
      padding: $content-padding $content-padding 15px $content-padding;
      button,
      .btn {
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  body {
    min-height: 100%;
  }

  .wrapper {
    .aside-container {
      .aside-inner {
        // padding-top: $navbar-hg;
      }
    }

    .section-container,
    .footer-container {
      margin-left: $aside-wd;
    }

    .section-container {
      // with just a margin we can make the
      // right sidebar always visible
      &.has-sidebar-right {
        margin-right: $aside-wd + 20;
        + .offsidebar {
          z-index: 1;
        }
      }
    }
  }
}

/* ========================================================================
   Component: top-navbar
 ========================================================================== */

//
// Horizontal top navbar
// based on the bootstrap navbar styles and markup
// ----------------------------------------------------

$nav-header-wd: $aside-wd;
$nav-header-wd-toggled: $aside-wd-collapsed;

$nav-header-wd-text: $aside-wd-collapsed-text;

$nav-top-item-mobile: $nav-top-bg;
$nav-top-item-mobile-active: darken($nav-top-item-mobile, 20%);

$nav-top-item-desktop: #fff;
$nav-top-item-desktop-active: $nav-top-item-mobile-active;

.subnavbar {
  -webkit-backface-visibility: hidden; /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  border: 0;
  padding: 0;
  position: relative;
  .navbar-nav > .nav-item > .navbar-text {
    color: $primary;
  }

  .navbar-nav > .nav-item > .nav-link {
    padding: 1.1rem 0.95rem;
    font-size: 0.85rem;
  }
  .navbar-nav > .nav-item > .nav-link,
  .navbar-nav > .nav-item.show > .nav-link {
    color: $nav-top-item-mobile;
    font-weight: normal;
    &:hover,
    &:focus {
      color: $nav-top-item-mobile-active;
    }
  } // Navbar link active style
  .navbar-nav > .nav-item.active > .nav-link,
  .navbar-nav > .nav-item.show > .nav-link {
    &,
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.submenu {
  padding-top: 0px;
  padding-bottom: 0px;
  .subnavbar .nav-tabs > .nav-item > .nav-link {
    border: 1px solid transparent;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: transparent;
    color: $primary;
    &:hover,
    &:focus {
      color: $nav-top-item-mobile-active;
    }
  }
  .subnavbar .nav-tabs > .nav-item.active > .nav-link {
    border-bottom: 1px solid $primary;
    color: $nav-top-item-mobile-active;
  }
}

// Navbar top layout
.topnavbar {
  -webkit-backface-visibility: hidden; /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  z-index: 1050;
  border: 0;
  padding: 0;
  min-height: $navbar-height;
  background-color: $nav-top-bg;
  background-image: linear-gradient(
    115deg,
    $nav-top-bg-start,
    $nav-top-bg-end
  ); // // dont' allow stacked icons

  @include media-breakpoint-up(md) {
    .navbar-header {
      width: $nav-header-wd;
      text-align: center;
      .navbar-brand {
        width: 100%;
      }
    }
  }
}

// Navbar Mobile General styles
// ------------------------------
.topnavbar,
.aside-container {
  position: relative;

  .navbar-header {
    background-color: $nav-header-bg;
  }

  .navbar-header {
    position: relative;
    z-index: 11;
    padding-left: 2rem;

    @include media-breakpoint-up(md) {
      padding-left: 0;
    } // Reset default to allow handle via different logo size
    .navbar-brand {
      padding: 0;
    } // Different Logo size depends on the navbar state
    .brand-logo,
    .brand-logo-collapsed {
      > img {
        margin: 0 auto;
      }
    }
    .brand-logo {
      display: block;
      padding: 2px 8px;
      min-height: 39px;
    }
    .brand-logo-collapsed {
      display: none;
      padding: 0px;
      min-height: 39px;
      width: 39px;
    }
  }

  .dropdown {
    position: static; // allow header to be parent of dropdown
    .dropdown-menu {
      position: absolute;
      margin-top: 0; // close to header
      top: auto;
      left: 0;
      right: 0;
    }
  } // Top navbar dropdown on desktop
  @include media-breakpoint-up(lg) {
    .dropdown {
      position: relative;
      .dropdown-menu {
        // standard left alignment
        top: $navbar-height - 1;
        left: 0;
        right: auto;
      } // redefined since we don't used navbar-expand-* class
      .dropdown-menu-right {
        right: 0;
        left: auto;
      }
    }
  }
  .navbar-nav > .nav-item > .navbar-text {
    color: $nav-top-item-desktop;
  }

  .navbar-nav > .nav-item > .nav-link {
    padding: 1.1rem 0.95rem;
    font-size: 0.85rem;
  }
  .navbar-nav > .nav-item > .nav-link,
  .navbar-nav > .nav-item.show > .nav-link {
    color: $nav-top-item-desktop;
    &:hover,
    &:focus {
      color: $primary;
    }
  } // Navbar link active style
  .navbar-nav > .nav-item.active > .nav-link,
  .navbar-nav > .nav-item.show > .nav-link {
    &,
    &:hover,
    &:focus {
      background-color: transparent;
    }
  } // the button to toggle search
  .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: $navbar-height;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    @include transition(color 0.3s ease);
    @include media-breakpoint-up(md) {
      color: $nav-top-item-desktop;
    }
  }

  @include media-breakpoint-down(sm) {
    .navbar-text {
      margin: 10px;
    }
  }
}

.aside-collapsed {
  .topnavbar,
  .aside-container {
    .navbar-header {
      .brand-logo {
        display: none;
      }
      .brand-logo-collapsed {
        display: block;
      }
    }

    .navbar-header {
      width: $nav-header-wd;
    }

    .navbar-form {
      left: $nav-header-wd;
    }
  }
}

// Customize header for horizontal layout
.layout-h {
  .topnavbar {
    // flex-flow: wrap;
    .navbar-header {
      display: flex;
      width: 100%;
      padding: 0;
      text-align: left;
      @include media-breakpoint-up(lg) {
        width: auto;
      }
      .navbar-brand {
        min-height: $navbar-height;
        .brand-logo {
          display: block;
          padding: 10px 10px;
          min-height: 39px;
        }
        .brand-logo-collapsed {
          display: none;
          padding: 0px;
          min-height: 39px;
          width: 39px;
        }
      }
    }
    .navbar-toggler {
      border: 0;
      margin-left: auto;
      .navbar-toggler-icon {
        $button-toggler-icon-color: rgba(255, 255, 255, 0.75);
        background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='#{$button-toggler-icon-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
      }
    }
    // make menu full size
    .dropdown-menu {
      @include media-breakpoint-down(md) {
        left: 0 !important;
        right: 0 !important;
      }
    }
    .navbar-form {
      left: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .sidebar-toggle {
    position: absolute !important;
    top: 2px;
    left: 0;
    z-index: 3001; // Add color only for the icon
    > em {
      color: white;
    }
  }
}

.card {
  border-top-width: 1px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-color: $color-grey-border;
  &.card-default {
    border-top-width: 1px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-color: $color-grey-border;
  }
  .card-header {
    background-color: $color-koncert-white;
  }

  .table {
    thead > tr > th,
    thead > tr > td {
      color: $color-dark-midnight-express;
      font-weight: bold;
    }
  }
}

// Navbar Search Form
// ------------------------------
.topnavbar {
  .navbar-form {
    position: absolute;
    top: -100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    height: $navbar-height;
    z-index: 9001;
    transition: all 0.3s;
    border: 0;
    border-bottom: 1px solid #e1e2e3;

    .form-group {
      height: 100%;
      width: 100%;
    }

    .form-control {
      height: 100%;
      border: 0;
      border-radius: 0;
      width: 100%;
    }
    &.open {
      top: 0;
    } // re locate and center feedback
    .navbar-form-close {
      position: absolute;
      height: 30px;
      cursor: pointer;
      top: 50%;
      right: 0;
      margin-top: -15px;
      line-height: 30px;
      margin-right: 10px;
      color: #c1c2c3;
      font-size: 1.5em;
      pointer-events: auto; // make icon clickable
    }
  }

  @include media-breakpoint-up(sm) {
    .navbar-form {
      left: $nav-header-wd;
    }
  }
}

// Navbar Desktop styles
// ------------------------------
@include media-breakpoint-up(lg) {
  // Navbar top styles
  .topnavbar {
    .navbar-nav > .nav-item.show > .nav-link {
      &,
      &:hover,
      &:focus {
        box-shadow: 0 -3px 0 darken($nav-top-bg, 6%) inset;
        @include transition(all 0.2s);
      }
    }
  } // .topnavbar
}

@include media-breakpoint-up(md) {
  .aside-collapsed {
    .topnavbar {
      .navbar-header {
        .brand-logo {
          display: none;
        }
        .brand-logo-collapsed {
          display: block;
        }
      }

      .navbar-header {
        width: $nav-header-wd-toggled + 20px;
      }

      .navbar-form {
        left: $nav-header-wd;
      }
    }
  }
}

// Header on aside collapsed with Text
@include media-breakpoint-up(md) {
  .aside-collapsed-text {
    .topnavbar {
      .navbar-header {
        .brand-logo {
          display: none;
        }
        .brand-logo-collapsed {
          display: block;
        }
      }

      .navbar-header {
        width: $nav-header-wd - $nav-header-wd-toggled + 20;
      }

      .navbar-form {
        left: $nav-header-wd;
      }
    }
  }
}

.message-plot-point {
  // background-color: red;
  margin: 0.1rem 0;

  .badge {
    margin-bottom: 0.5rem;
  }

  .plot-point-inner {
    padding: 0.2rem 0.5rem;
    border: 2px dashed #efefef;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: #656565;

    &.neutral-quality {
      background-color: #fefefe;
    }

    &.positive-quality {
      background-color: lighten($green, 47%);
    }

    &:hover {
      border: 2px solid #0085d6;
      &.neutral-quality {
        background-color: #e7e7e7;
      }

      &.positive-quality {
        background-color: lighten($green, 30%);
      }
    }
  }

  &.subject {
    font-weight: bold;
    .plot-point-inner::before {
      display: inline;
      content: 'Subject: ';
    }
  }
}

.plot-point-popover-header {
  background-color: $color-koncert-white;
  color: $body-color;
}

img.bw {
  transition: filter 0.5s ease-in-out;
  -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(100%); /* FF 35+ */
  &:hover {
    transition: filter 0.5s ease-in-out;
    -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
    filter: grayscale(0%); /* FF 35+ */
  }
}

.page-link {
  color: $primary;
  &:hover {
    color: $primary;
  }
}
