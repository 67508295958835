$icon-font-family: 'icon' !default;
$icon-font-path: 'fonts' !default;

$trucadence-icon: '\e907';
$koncert-cadence-icon: '\e950';
$koncert-cadence-icon-flipped: '\e931';
$speed-30-path1: '\e901';
$speed-30-path2: '\e902';
$speed-30-path3: '\e903';
$speed-30-path4: '\e904';
$speed-30-path5: '\e905';

$speed-800-path1: '\e909';
$speed-800-path2: '\e90a';
$speed-800-path3: '\e90b';
$speed-800-path4: '\e90c';
$speed-800-path5: '\e900';

$speed-100-path1: '\e906';
$speed-100-path2: '\e908';
$speed-100-path3: '\e91b';
$speed-100-path4: '\e91c';
$speed-100-path5: '\e91d';
$talktime: '\e90d';
$call-red-circle-path1: '\e90f';
$call-red-circle-path2: '\e910';
$personal-dialor: '\e911';
$right-arrow: '\e912';
$thumb: '\e913';
$twoway_icon_white: '\e914';
$download: '\e915';
$upload-one: '\e916';
$voice: '\e917';
$zig-zag: '\e918';
$calling: '\e919';
$reports_icon_black: '\e923';
$accounts_icon_black: '\e91e';
$mycadence_icon_black: '\e921';
$pending_calls_icon_black: '\e922';
$todo_icon_black: '\e924';
$dashboard_icon_black: '\e91f';
$email_icon_black: '\e920';
$emailEdit: '\e929';
$zipwhip-path1: '\e92a';
$zipwhip-path2: '\e92b';
$zipwhip-path3: '\e92c';
