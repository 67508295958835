// Keep this is for Report only
.slick-arrow {
  &:before {
    content: '\f054';
    color: $color-regent-gray;
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1;
    opacity: 1;
  }
  &:hover:before {
    color: $gradient-turquoise-dream-end;
  }
}
.slick-prev {
  &:before {
    content: '\f053';
  }
}
.slick-next {
  &:before {
    content: '\f054';
  }
}
.slick-dots {
  li {
    margin: 0 2px;
    padding: 3px;
    width: 16px;
    height: 16px;
    button {
      width: 16px;
      height: 16px;
      &:before {
        color: $color-regent-gray;
        font-size: 8px;
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
    }
    &.slick-active {
      button {
        &:before {
          color: $color-regent-gray;
          opacity: 1;
        }
      }
    }
  }
}
.align-middle {
  .slick-track {
    display: flex;
    align-items: center;
  }
}
.right-align-slider {
  .slick-track {
    margin-left: auto;
    margin-right: initial;
  }
}
